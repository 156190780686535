<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          商户列表
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->
          <b-button variant="primary" @click="create()" class="mx-2">
            <b-icon icon="plus"></b-icon>
            新增
          </b-button>

          <!--按钮组-->
          <b-button variant="success" @click="edit()" class="mx-2">
            <b-icon icon="pencil"></b-icon>
            编辑
          </b-button>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <!--表格-->
          <v-data-table show-select fixed-header dense height="70vh" v-model="table.selected" :headers="table.headers"
            :items="table.desserts" :single-select="table.singleSelect" :footer-props="table.footer"
            :server-items-length="table.itemsCount" :options.sync="table.pagination" :no-data-text="table.noDataText"
            :loading="table.loading" :loading-text="table.loadingText" item-key="id">

            <template v-slot:item.status="{ item }">
              <v-switch class="mt-0 pt-0" v-model="item.status" :true-value="1" :false-value="0"
                @change="onSwitchStatus(item)" color="success" hide-details></v-switch>
            </template>



            <template v-slot:item.payFeeRate="{ item }">
              <div :key="key" v-for="(value, key) in JSON.parse(item.payFeeRate)">
                {{ key }} : {{ value }}
              </div>
            </template>


            <template v-slot:item.updateTime="{ item }">
              {{ item.updateTime | formatDate }}
            </template>



            <!--状态字段   格式处理-->
            <template v-slot:item.action="{ item }">
              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="编辑" v-on:click="edit(item.merchantId)">
                <v-icon>la la-edit</v-icon>
              </v-btn>


              <v-btn small text icon fab v-b-tooltip.hover.lefttop title="资金账户"
                v-on:click="fundAccount(item.merchantId)">
                <v-icon>la-comment-dollar</v-icon>
              </v-btn>
            </template>

            <!--表格筛选-->
            <template v-slot:top>
              <b-form inline>
                <b-form-group>
                  <b-form-select :options="filter.conditions" v-model="filter.field"></b-form-select>
                </b-form-group>
                <label class="after-label ml-2 pb-md-2 pb-lg-2 pb-xl-2 pb-sm-3 pb-3">:</label>
                <b-form-group class="mx-2">
                  <b-form-input id="input-2" v-model="filter.keyword" placeholder="检索关键词"></b-form-input>
                </b-form-group>

                <b-form-group class="mx-2 ml-md-2 ml-lg-2 ml-xl-2 ml-sm-0 ml-0">
                  <b-form-select :options="filter.statusOptions" v-model="filter.status"></b-form-select>
                </b-form-group>

                <b-form-group class="mx-2">
                  <b-button variant="success" v-on:click="getData()">
                    <v-icon small> flaticon-search</v-icon>
                    查询
                  </b-button>
                </b-form-group>
              </b-form>

              <!--分隔线-->
              <v-divider></v-divider>
            </template>
          </v-data-table>
        </div>
      </template>
    </KTCard>


  </div>
</template>

<script>
import Vue from "vue";

import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "List",
  components: {
    KTCard,
  },
  props: {
    title: String,
  },
  data() {
    return {

      filter: {
        conditions: [{
          value: null,
          text: "检索条件"
        },
        {
          value: "supplierName",
          text: "供货商名称"
        },
        {
          value: "loginAccount",
          text: "登录账号"
        },
        {
          value: "contactPhone",
          text: "联系电话"
        },
        {
          value: "email",
          text: "电子邮箱"
        },
        ],
        statusOptions: [{
          value: null,
          text: "状态"
        },
        {
          value: 0,
          text: "禁用"
        },
        {
          value: 2,
          text: "待审核"
        },
        {
          value: 1,
          text: "正常"
        },
        ],
        field: null,
        keyword: "",
        status: null,
      },

      table: {
        loadingText: "正在加载...",
        noDataText: "暂无数据...",
        loading: false,
        singleSelect: false,
        selected: [],
        itemsCount: 20,
        headers: [

          {
            text: "商户ID",
            value: "merchantId"
          },
          {
            text: "商户名称",
            value: "merchantName"
          },
          {
            text: "通知邮箱",
            value: "email"
          },
          {
            text: "对账QQ",
            value: "qq"
          },
          {
            text: "联系电话",
            value: "telPhone"
          },
          {
            text: "费率",
            value: "payFeeRate"
          },
          {
            text: "USDT汇率",
            value: "usdtRate"
          },
          // {
          //   text: "代付通道",
          //   value: "withdrawChannelName"
          // },
          {
            text: "提现手续费",
            value: "withdrawFee"
          },
          {
            text: "提现费率",
            value: "withdrawRate"
          },
          {
            text: "状态",
            value: "status"
          },
          {
            text: "更新时间",
            value: "updateTime"
          },
          {
            text: "操作",
            value: "action"
          },
        ],
        footer: {
          itemsPerPageOptions: [5, 10, 20, 50, 100],
          itemsPerPageText: "分页数量",
          showCurrentPage: true,
        },
        pagination: {
          itemsPerPage: 20,
        },
        desserts: [],
      },
    };
  },
  mounted() {
    // Create a FormValidation instance

  },
  created() { },

  methods: {
    create() {
      this.$router.push({
        path: "/merchant/edit",
      });
    },
    edit(id = undefined) {
      this.submitUrl = "/api/merchant/manage/update";
      id = id == undefined ? this.getSelectedIds() : [id];
      if (id.length != 1) {
        Swal.fire({
          title: "",
          text: "请选择要编辑的数据行,一次仅且只能选择一条!",
          icon: "warning",
          confirmButtonClass: "btn btn-success",
          heightAuto: false,
        });
        return;
      }

      this.$router.push({
        path: "/merchant/edit",
        query: {
          merchantId: id[0],
        },
      });
    },

    fundAccount(mchId) {
      this.$router.push({
        path: "/merchant/fund/account",
        query: {
          merchantId: mchId,
        },
      });
    },

    onSwitchStatus(item) {
      let that = this;
      ApiService.post("/api/merchant/manage/update", {
        merchantId: item.merchantId,
        status: item.status,
      }).then(({
        data
      }) => {
        if (data.success) {
          Swal.fire({
            title: "",
            text: "修改成功！",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 1500,
          });
          that.getData();
        } else {
          Swal.fire({
            title: "修改失败！",
            text: data.message,
            icon: "warning",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
            timer: 3000,
          });
        }
      });
    },



    getData() {
      let params = {
        pageNum: this.table.pagination.page,
        pageSize: this.table.pagination.itemsPerPage,
        status: this.filter.status,
      };
      params["" + this.filter.field + ""] = this.filter.keyword;

      ApiService.query("/api/merchant/manage/query_list", {
        params: params,
      }).then(({
        data
      }) => {
        this.table.desserts = data.result.data;
        this.table.itemsCount = data.result.total;
      });
    },

    getSelectedIds() {
      let ids = [];
      if (this.table.selected != null && this.table.selected != undefined) {
        this.table.selected.forEach(function (item) {
          ids.push(item.merchantId);
        });
      }
      return ids;
    },

    onClose() {
      this.$refs["my-modal"].hide();
    },

  },
  computed: {
    pagination: function () {
      return this.table.pagination;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>